
    import { mapGetters, mapState } from 'vuex';
    import BaseModal from './BaseModal';

    export default {
        name: 'VoteConfirmationModal',

        extends: BaseModal,

        data () {
            return {
                modalName: 'vote_confirmation',
            };
        },

        computed: {
            ...mapGetters({
                votingComplete: 'recipe_voting/votingComplete',
            }),
            ...mapState(['recipe_voting']),
        },

        i18nOptions: { namespaces: ['vote_confirmation'] },
    };
