
    import BaseModal from './BaseModal';

    export default {
        name: 'PrizeDetailsModal',

        extends: BaseModal,

        i18nOptions: { namespaces: ['prizes'] },
    };
