
import axios from 'axios';
import { reactive, set } from 'vue';
import api from '../../api';

const getDefaultState = () => reactive({
    gameLimits: {},
});

const state = getDefaultState();

const getters = {
    isVotingLimited: (state) => state?.recipe_voting?.sweepsEarned === state?.recipe_voting?.totalSweeps,
};

const mutations = {
    setData (state, dataObject)   {
        for (const key in dataObject) {
            set(state, key, dataObject[key]);
        }
    },
    setGameLimits (state, dataObject)   {
        for (const key in dataObject) {
            state.gameLimits[key] = dataObject[key];
        }
    },

};

const actions = {
    async getLimitStatus ({ commit }) {
        const response = await axios.get(`${api.base}/awards/hub-actions-status`);

        commit('setData', response.data.result);
    },

    async getGamePlayLimits ({ commit }) {
        const response = await axios.get(`${api.base}/game/test-play-limits`);
        commit('setGameLimits', response.data);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
