
// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    LaunchedEmailCollection = async () => import(
        /* webpackChunkName: 'launched' */
        '../views/LaunchedEmailCollection.vue'
    ),
    LaunchedThanks = async () => import(
        /* webpackChunkName: 'launched' */
        '../views/LaunchedThanks.vue'
    );

export default [
    {
        path: '/launched',
        alias: '/',
        component: LaunchedEmailCollection,
        meta: {
            public: true,
            activeInPhase: ['launched'],
        },
    },
    {
        path: '/launched_thanks',
        alias: '/',
        component: LaunchedThanks,
        meta: {
            public: true,
            activeInPhase: ['launched'],
        },
    },
];
