
    import BaseModal from './BaseModal';

    export default {
        name: 'WelcomeModal',

        extends: BaseModal,

        data () {
            return {
                loggedInMethod: this.$store.state.profile.loggedInMethod,
                modalName: 'welcome',
            };
        },

        i18nOptions: { namespaces: ['welcome_modal'] },
    };
