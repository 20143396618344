
    import { mapActions, mapState } from 'vuex';
    import BaseModal from './BaseModal';

    export default {
        name: 'GameOverModal',

        extends: BaseModal,

        data () {
            return {
                modalName: 'game_over',
                hasPlayedInstantWinGame: false,
            };
        },

        computed: {
            ...mapState(['game', 'hub']),
        },

        i18nOptions: { namespaces: ['game_over'] },

        async mounted () {
            await this.$store.dispatch('hub/getGamePlayLimits');
            this.hasPlayedInstantWinGame = this.hub.gameLimits?.iwGamePlayedToday;
            this.getGameScores();
        },

        methods: {
            ...mapActions({
                getGameScores: 'game/getGameScores',
            }),
            ...mapActions('award', ['generateToken', 'play']),

            async navigateToGameResult () {
                await this.generateToken();
                await this.play();
                const { prizeWon } = this.$store.state.award;


                this.closeModal();

                if (prizeWon) {
                    return this.$router.replace({ path: '/inky' });
                }

                return this.$router.replace({ path: '/janky' });
            },

            backToHub () {
                this.closeModal();
                return this.$router.replace({ path: '/hub' });
            },
        },
    };
