
    import { mapState } from 'vuex';
    import { openPopup } from '../popup';
    import openModal, { PrizeDetailsModal } from '../modals';

    export default {
        name: 'HamburgerMenu',

        data () {
            return {
                menuOpen: false,
            };
        },

        computed: {
            ...mapState(['app']),
            iconTitle () {
                const state = this.menuOpen ? 'Close' : 'Open';
                return `${state} Menu`;
            },
        },

        methods: {
            openPopup,

            toggleMenu () {
                this.menuOpen = !this.menuOpen;
            },
            openPrizeDetailsModal () {
                this.toggleMenu();
                openModal(PrizeDetailsModal);
            },
        },

        i18nOptions: { namespaces: ['links', 'menu'] },
    };
