
    import { mapState } from 'vuex';
    import BaseModal from './BaseModal';

    export default {
        name: 'HowToPlayModal',

        extends: BaseModal,

        props: {
            // Note: if you pull these values from copydeck, you need to make
            // sure the namespace is loaded before we need this component, ideally at
            // app startup, since the user may not have a viable Internet connection
            // when this modal appears.
            headline: {
                type: String,
                default: 'An unexpected error occurred.',
            },
            body: {
                type: String,
                default: 'Please try again later.',
            },
        },

        data () {
            return {
                modalName: 'how_to_play',
                hasPlayedInstantWinGame: false,
            };
        },

        computed: {
            ...mapState(['hub']),
        },

        async mounted () {
            await this.$store.dispatch('hub/getGamePlayLimits');
            this.hasPlayedInstantWinGame = this.hub.gameLimits?.iwGamePlayedToday;
        },

        i18nOptions: { namespaces: ['how_to_play'] },

    };
