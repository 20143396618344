
import axios from 'axios';
import { reactive, set } from 'vue';
import api from '../../api';

const getDefaultState = () => reactive({
});

const state = getDefaultState();

const formData = new FormData();

const axiosInstance = axios.create({});
delete axiosInstance.defaults.headers.common['X-HW-Profile-Token'];
const options = { headers: { 'Content-Type': undefined } };

const getters = {
};

const mutations = {
    setPhotoUpload (state, dataObject)   {
        for (const key in dataObject) {
            set(state, key, dataObject[key]);
        }
    },
    insertImageIntoForm (state, image) {
        formData.append('upload', image);
    },
};

const actions = {
    async submitPhotoUpload ({ state, commit }, data) {
        const response = await axios.post(`${api.base}/hello-uploader/key`, data);
        commit('setPhotoUpload', response.data);
        return response;
    },
    async saveImage ({ rootState, state }) {
        const s3ImageUrl = `${rootState.app.siteURL.split('//')[0]}//${state.uploadHost}/uploader/v3/upload/?key=${state.key}`;
        const response = await axiosInstance.post(s3ImageUrl, formData, options);
        return response;
    },
    async submitPhoto ({ dispatch, commit, rootState }) {
        let key, imageUrl, uploadHost;
        try {
            const response = await dispatch('submitPhotoUpload', {
                uid: rootState.profile.id,
                type: 'photo',
                language: rootState.app.userLanguage,
            });
            ({ key, uploadHost } = response.data);
            commit('setPhotoUpload', { key, uploadHost });
        }
        catch (error) {
            console.error('could not get uploader key', error);
        }

        if (key && uploadHost) {
            try {
                commit('insertImageIntoForm', rootState.app.userImage);

                const response = await dispatch('saveImage');

                imageUrl = response.data.outputs.orig.url;
            }
            catch (error) {
                console.error('could not upload file', error);
            }
        }

        if (imageUrl) {
            try {
                const tempPhotoUpload = {};
                tempPhotoUpload.photoUrl = imageUrl;
                tempPhotoUpload.originalWork = true;
                tempPhotoUpload.officialRules = true;
                const response = await axios.post(`${api.base}/photo/submit`, tempPhotoUpload);
                return response;
            }
            catch (error) {
                console.error('could not submit image', error);
            }
        }
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
