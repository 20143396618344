
// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    WinnerPage = async () => import(
        /* webpackChunkName: 'winner' */
        '../views/WinnerPage.vue'
    );

export default [
    {
        path: '/winner',
        alias: '/',
        component: WinnerPage,
        meta: {
            public: true,
            activeInPhase: ['winner_page', 'expired'],
        },
    },
];
