
// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    PreviewPage = async () => import(
        /* webpackChunkName: 'upload' */
        '../views/PreviewPage.vue'
    ),
    ThanksPage = async () => import(
        /* webpackChunkName: 'upload' */
        '../views/ThanksPage.vue'
    ),
    UploadPage = async () => import(
        /* webpackChunkName: 'upload' */
        '../views/UploadPage.vue'
    );

export default [
    { path: '/preview', component: PreviewPage },
    { path: '/thanks', component: ThanksPage },
    { path: '/upload', component: UploadPage },
];
